import React from "react"

import EmbedLayout from "../components/embed-layout"
import { CareerPortalPageContent } from '../components/careerportalpage-content';

const CareerPortalEmbed = () => (
  <EmbedLayout>
    <CareerPortalPageContent></CareerPortalPageContent>
  </EmbedLayout>
)

export default CareerPortalEmbed
