/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React , { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import "./layout.css";

const EmbedLayout = ({ children }) => {

  return (
    <>
      <div className='layout-container'>
        <div>
          <main>{children}</main>
        </div>
      </div>
    </>
  )
}

EmbedLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default EmbedLayout
